// src/routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Accueil from "./pages/Accueil";
import Categories from "./pages/Categories";
import Propos from "./pages/Apropos";
import Recherche from "./pages/Recherche";
import FicheFilm from "./pages/FicheFilm";
import ActeurDetails from "./pages/ActeurDetails";
import FilmPop from "./pages/FillmsPopulaires";
import CreerCompte from "./pages/CreerCompte";
import Connexion from "./pages/Connexion";
import ProfilMember from "./pages/ProfilMember";
import EditProfil from "./pages/EditProfil";
import EmuleProfil from "./components/EmuleProfil";
import Forum from "./pages/Forum";
import EditSubjectForumPage from "./components/EditSubjectForumPage";
import SubjectPage from "./components/SubjectPage";
import EditReply from "./components/EditReply";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Accueil />} />
    <Route path="/categorie" element={<Categories />} />
    <Route path="/Propos" element={<Propos />} />
    <Route path="/Recherche" element={<Recherche />} />
    <Route path="/categories/:selectedCategory" element={<Categories />} />
    <Route path="/ActeurDetails/:id" element={<ActeurDetails />} />
    <Route path="/ficheFilm/:id" element={<FicheFilm />} />
    <Route path="/FilmsPopulaires" element={<FilmPop />} />
    <Route path="/creercompte" element={<CreerCompte />} />
    <Route path="/Connexion" element={<Connexion />} />
    <Route path="/profilMemberPage/:userId" element={<ProfilMember />} />
    <Route path="/EditProfilPage/:userId" element={<EditProfil />} />
    <Route path="/EmuleProfil/:userId" element={<EmuleProfil />} />
    <Route path="/Forum" element={<Forum />} />
    <Route path="/edit-subject/:subjectId" element={<EditSubjectForumPage />} />
    <Route path="/subject/:id" element={<SubjectPage />} />
    <Route path="/edit-reply/:postId/:replyId" element={<EditReply />} />
  </Routes>
);

export default AppRoutes;

  
  

