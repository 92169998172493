import React, { useState, useEffect, useContext } from "react";
import NavigationBar from "../components/Navigation";
import Footer from "../components/Footer";
import {
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  where,
  limit,
  startAfter,
  setLastVisible,
  lastVisible,
  timestamp,
} from "firebase/firestore";
import { db } from "../config/firebasedeconfig";
import CreatePostModal from "../components/CreatePostModal";
import "../styles/components/Forum.scss";
import { OnlineUsersContext } from "../OnlineUsersContext";
import { UserContext } from "../UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultAvatarUrl, defaultUsername, defaultEmail } from '../constants';

const Forum = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onlineUsers } = useContext(OnlineUsersContext);
  const { username, isLoggedIn, setRedirectTo, userId, messageCount, updateMessageCount, fetchUserMessageCount } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showRules, setShowRules] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [onlineUsersCheck, setOnlineUsersCheck] = useState([]);
  const uid = localStorage.getItem("uid");
  const [editingPost, setEditingPost] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editContent, setEditContent] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const { avatar, setAvatar } = useContext(UserContext);
  

  // Liste des catégories
  const categories = [
    { id: "action", name: "Action" },
    { id: "romance", name: "Romance" },
    { id: "horror", name: "Horreur" },
    { id: "new", name: "Nouveautés" },
    { id: "classics", name: "Classiques" },
  ];

  // Liste des posts
  const [lastVisible, setLastVisible] = useState(null);

  // Récupérer les posts depuis Firestore
  const fetchPosts = async () => {
    try {
      if (!db) {
        console.error("Erreur : pas de base de données");
        return;
      }
  
      const postsQuery = query(
        collection(db, "posts"),
        orderBy("timestamp", "desc"),
        limit(10)
      );
  
      const querySnapshot = await getDocs(postsQuery);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  
      if (querySnapshot.empty) {
        console.log("Aucun post.");
        setPosts([]); // Réinitialise l'état si aucun post
        return;
      }

      const postsData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
            const post = {
                id: doc.id,
                ...doc.data(),
            };

            if (!post.content) return null;

            post.content = post.content.replace(/(<([^>]+)>)/gi, ""); // Nettoie le contenu
            post.messageCount = await fetchUserMessageCount(post.authorId); // Récupère le nombre global
            return post;
        })
      );

           // Récupère les informations de l'auteur
           for (const post of postsData) {
            if (!post || !post.authorId) {
              console.warn("Post sans auteur, ignorer : ", post);
              continue;
            }
    
            const userDocRef = doc(db, "users", post.authorId);
            const userDoc = await getDoc(userDocRef);
    
            if (!userDoc.exists()) {
              console.warn("Auteur inconnu, ignorer : ", post.authorId);
              continue;
            }
    
            const userData = userDoc.data();
            if (!userData) {
              console.error("Auteur sans données, ignorer : ", post.authorId);
              continue;
            }
    
            post.avatar = userData.avatar || defaultAvatarUrl;
            post.authorName = userData.username || "Utilisateur inconnu";
          }
    
          console.log("Posts sélectionnés :", postsData);
  
      setPosts(postsData.filter(Boolean)); // Supprime les valeurs nulles
      setLastVisible(lastDoc);
    } catch (error) {
      console.error("Erreur lors de la récupération des posts :", error);
    }
  };
  

  // Récupérer l'avatar du créateur du post
  const fetchAvatar = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAvatar(data.avatar);
      }
    } catch (error) {
      console.error("Erreur lors de la sélection de l'utilisateur :", error);
    }
  };



  // Charger plus de posts
  const loadMorePosts = async () => {
    try {
      const nextQuery = query(
        collection(db, "posts"),
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(10)
      );

      const querySnapshot = await getDocs(nextQuery);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      setPosts(prevPosts => [
        ...prevPosts,
        ...querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ]);
      setLastVisible(lastDoc);
    } catch (error) {
      console.error("Erreur lors du chargement des posts :", error);
    }
  };

  const sortPosts = async (sortBy = "timestamp") => {
    const postsQuery = query(
      collection(db, "posts"),
      orderBy(sortBy, "desc"),
      limit(10)
    );
    const querySnapshot = await getDocs(postsQuery);
    const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return posts; // Return the posts array
  };


  useEffect(() => {
    fetchPosts(); // Récupère les posts au chargement
    sortPosts(); // Call the sortPosts function when the component mounts
    loadMorePosts(); // Call the loadMorePosts function when the component mounts
    fetchAvatar(userId);
  }, []);

  // Filtrer les posts en fonction de la recherche et de la catégorie
  const filteredPosts = posts.filter((post) => {
    const matchesSearch = post.title?.toLowerCase()?.includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategory === "all" || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });
  

  // Gestion du clic sur une catégorie
  const handleCategorySelect = (id) => {
    setSelectedCategory(id);
    setDropdownOpen(false);
  };

  // Gestion de l'ouverture du modal pour créer un post
  const handleCreatePost = () => {
    if (!isLoggedIn) {
      setRedirectTo("/Forum?openModal=true"); // Stocke l'intention
      navigate("/connexion"); // Redirige vers la page de connexion
    } else {
      setIsModalOpen(true); // Ouvre le modal
    }
  };

  // Détecter le paramètre `openModal` dans l'URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("openModal") === "true") {
      setIsModalOpen(true);
    }
  }, [location]);


  // Gestion de la suppression d'un post
  const handleDelete = async (postId) => {
    console.log("Suppression du post avec ID :", postId); // Log pour confirmer l'ID
    try {
      // Supprime le document dans Firestore
      await deleteDoc(doc(db, "posts", postId));
      
      // Met à jour l'état local immédiatement après suppression
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Erreur lors de la suppression du post : ", error);

      // Message visuel pour l'utilisateur
      alert("Une erreur est survenue lors de la suppression du sujet. Veuillez réessayer.");
    }
  };



  const setUserOnline = async (uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { isOnline: true });
  };

  const setUserOffline = async (uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { isOnline: false });
  };

  useEffect(() => {
    if (uid) {
      setUserOnline(uid); // Définit l'utilisateur comme en ligne
    }

    // Nettoyage à la suppression du composant ou au changement de uid
    return () => {
      if (uid) {
        setUserOffline(uid); // Définit l'utilisateur comme hors ligne avant de quitter
      }
    };
  }, [uid]);

  const fetchOnlineUsers = () => {
    const q = query(collection(db, "users"), where("isOnline", "==", true)); // Filtrer les utilisateurs en ligne
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const onlineUsers = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      console.log("Utilisateurs en ligne :", onlineUsers);
      setOnlineUsersCheck(onlineUsers); // Met à jour l'état avec les utilisateurs en ligne
    });

    return unsubscribe; // Retourne l'écouteur pour le nettoyage
  };

  useEffect(() => {
    const unsubscribe = fetchOnlineUsers(); // Récupère l'écouteur
    return () => unsubscribe(); // Nettoyage à la suppression du composant
  }, []);

  const limitedUsers = onlineUsers.slice(0, 10); // Affiche les 10 premiers utilisateurs en ligne

  // Pour modifier un post
  const handleEdit = (post) => {
    navigate(`/edit-subject/${post.id}`);
  };



  // Pour annuler la modification
  const handleCancelEdit = () => {
    setEditingPost(null);
    setEditTitle("");
    setEditContent("");
  };

  const handleSaveEdit = async () => {
    if (!editTitle || !editContent) {
      alert("Tous les champs doivent être remplis !");
      return;
    }

    try {
      const postRef = doc(db, "posts", editingPost); // Référence du document en cours d'édition
      await updateDoc(postRef, {
        title: editTitle,
        content: editContent,
        timestamp: serverTimestamp(), // Met à jour le timestamp
      });
      fetchPosts(); // Actualise la liste des posts
      setEditingPost(null); // Ferme le mode édition
    } catch (error) {
      console.error("Erreur lors de la modification :", error);
    }
  };

  // Gestion du clic sur un sujet pour redirection
  const handleSubjectClick = (postId) => {
    navigate(`/subject/${postId}`); // Redirige vers la page du sujet
  };

  // // Gestion du comptage des réponses
  const fetchReplyCount = async (postId) => {
    try {
      // Compter les réponses dans la sous-collection "replies"
      const repliesQuery = query(collection(db, "posts", postId, "replies"));
      const repliesSnapshot = await getDocs(repliesQuery);
      const repliesCount = repliesSnapshot.size;
  
      // Inclure le post lui-même comme un "message"
      return repliesCount + 1; // Ajoute le sujet principal
    } catch (err) {
      console.error("Erreur lors du comptage des réponses :", err);
      return 1; // Par défaut, inclure au moins le sujet lui-même
    }
  };
  
  

  return (
    <>
      <div
        className="forum-page"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "overlay",
          backgroundColor: "#200211",
          opacity: "0.9",
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: "100vh",
        }}
      >
        <NavigationBar />
        <div className="forum-container">
          <h1 className={`forum-title ${isModalOpen ? "modal-open" : ""}`}>
            Forum MovieFrenzy
          </h1>
          <div className="forum-controls">
            <input
              type="text"
              placeholder="Rechercher des sujets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="custom-dropdown">
              <div
                className="selected-option"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {categories.find((cat) => cat.id === selectedCategory)?.name ||
                  "Toutes les catégories"}
                <span className={`chevron ${dropdownOpen ? "open" : ""}`}>▼</span>
              </div>
              {dropdownOpen && (
                <ul className="options-list">
                  <li onClick={() => handleCategorySelect("all")}>
                    Toutes les catégories
                  </li>
                  {categories.map((cat) => (
                    <li key={cat.id} onClick={() => handleCategorySelect(cat.id)}>
                      {cat.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button
              className="buttonForum01"
              onClick={() => setShowRules(!showRules)}
            >
              Afficher les Règles
            </button>
            <button className="buttonForum01" onClick={handleCreatePost}>
              Créer un sujet
            </button>
          </div>
          {showRules && (
            <div className="forum-rules">
              <h2>Charte de bonne conduite</h2>
              <ul>
                <li>Respectez les autres utilisateurs.</li>
                <li>Pas de spam ou de messages hors-sujet.</li>
                <li>Pas de spoilers sans avertissement clair.</li>
                <li>Utilisez un langage approprié.</li>
                <li>Signalez les contenus inappropriés.</li>
              </ul>
            </div>
          )}
          {posts && posts.length > 0 ? (
            posts.map((post) => (
              <div
                key={post.id}
                className="forum-post"
                style={{
                  marginBottom: "1.25rem",
                  padding: "1.5rem",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "0.5rem",
                  boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)",
                  cursor: "pointer", // Ajout pour indiquer que c'est cliquable
                  transition: "background-color 0.3s",
                  border: "2px solid #00FFFF",
                }}
                onClick={() => navigate(`/subject/${post.id}`)}
              // onClick={() => navigate(`/subject/${post.id}`)} // Redirection vers la page du sujet
              >
                <h3 className="Title_topic" style={{ color: "#00FFFF" }}>
                  {post.title}
                </h3>
                <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                {/* <p>{post.content}</p> */}
                <div
                  className="post-details"
                  style={{ display: "flex", justifyContent: "space-between", gap: "0.8rem", fontSize: "0.9rem", borderColor: "#FF33CC", borderTop: "2px solid #FF33CC", padding: "1.5rem", borderStyle: "dotted", filter: "brightness(0.9) drop-shadow(0 0 0.5rem #FF33CC)" }}
                >
                  <div className="avatar-containerForumHome" style={{ color: "#00FFFF", fontSize: "0.8rem", backgroundColor: "#200211", padding: "0.5rem", borderRadius: "0.25rem" }}>
                    Par :{" "}
                    <span style={{ fontWeight: "bold", color: "white" }}>
                      {post.author || "Auteur inconnu"}
                    </span>
                    <div className="avatar-containerForumHome"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "0.5rem",
                        gap: "0.5rem",
                        flexDirection: "row",
                      }}
                    >
                      <img
                        src={post.avatar || defaultAvatarUrl}
                        alt="Avatar de l'auteur"
                        className="avatar-subject"
                      />
                      <div className="avatar-containerForumHome"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.125rem",
                          fontSize: "0.8rem",
                          flexDirection: "column",
                          marginTop: "0.2rem",
                          marginLeft: "0.5rem",
                          color: "#00FFFF",
                        }}
                      >
                        <span className="avatar-containerForumTitle" style={{ color: "#00FFFF", marginRight: "5.75rem" }}>
                          Titre :{" "}
                        </span>
                        <span style={{ fontWeight: "bold", color: "white" }}>
                          Nbr de messages : {post.messageCount || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span style={{ color: "#00FFFF" }}>
                    Catégorie :{" "}
                    <span style={{ fontWeight: "bold", color: "white" }}>
                      {post.category}
                    </span>
                  </span>
                  <span
                    style={{
                      color: "#00FFFF",
                      fontSize: "0.8rem",
                      cursor: "pointer",
                      padding: "0.2rem",
                      borderRadius: "0.25rem",
                    }}
                  >
                    {post.timestamp?.seconds
                      ? new Date(post.timestamp.seconds * 1000).toLocaleString()
                      : "Date inconnue"}
                  </span>
                </div>
                {post.author === username && (
                  <div className="button-container">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Arrête la propagation vers le parent
                        handleEdit(post);
                      }}
                      className="editButton"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="deleteButton"
                    >
                      Supprimer
                    </button>
                  </div>
                )}
                {editingPost === post.id && (
                  <div>
                    <h3>Modifier le sujet</h3>
                    <label>
                      Titre :
                      <input
                        type="text"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                      />
                    </label>
                    <label>
                      Contenu :
                      <textarea
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                      />
                    </label>
                    <button onClick={handleSaveEdit} className="editButton">
                      Enregistrer
                    </button>
                    <button
                      onClick={() => handleCancelEdit()}
                      className="cancelButton"
                    >
                      Annuler
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>Aucun sujet disponible pour l'instant.</p>
          )}
          <button onClick={loadMorePosts} disabled={!lastVisible} className="buttonForumChargerMore">
            <span className="chargerSpanButtonForum">CHARGER PLUS</span>
          </button>
          <div className="forum-online-users" style={{ color: "#00FFFF", fontSize: "0.8rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>Utilisateurs en ligne</h2>
            {onlineUsersCheck.length === 0 ? (
              <p>Aucun utilisateur en ligne</p>
            ) : (
              <ul style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {onlineUsersCheck.map((user) => (
                  <li key={user.uid} style={{ marginBottom: "0.5rem" }}>{user.username || "Utilisateur anonyme"}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <CreatePostModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          fetchPosts={fetchPosts} // Renommez fetchTopics en fetchPosts pour consistance
        />
        {/* <CreatePostModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          fetchPosts={fetchPosts}
          dialogClassName="custom-modal-width"
        /> */}
        <Footer />
      </div>
    </>
  );
};

export default Forum;

