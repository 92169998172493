import React, { useState, useEffect, useContext } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import NavigationBar from "../components/Navigation";
import { placeholderImage } from "../constants";
import { useParams } from "react-router-dom";
import { UserContext } from '../UserContext';
import { db, auth } from "../config/firebasedeconfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Helmet } from "react-helmet"; // Import de Helmet pour le SEO

const ActeurDetails = (props) => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const placeholderImage = "https://zupimages.net/up/23/14/zdoe.jpg";

  const { isLoggedIn } = useContext(UserContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchActorDetails = async () => {
        try {
          const response = await axios.get(
            `https://api.themoviedb.org/3/person/${id}?api_key=e16900047627f56531a2d3d8d4df9e92&language=en-US&append_to_response=external_ids`
          );
          console.log("Response:", response.data);
          setDetail(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchActorDetails();
    }
  }, [id]);

  const handleImageError = (event) => {
    event.target.src = placeholderImage;
    console.log("Image error occurred. Placeholder image set.");
    console.log("Profile path:", detail.profile_path);
  };

  const addFavoriteActor = async () => {
    try {
      if (!isLoggedIn || !detail) return;

      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();

      if (!userSnap.exists()) {
        console.error("No such user!");
        return;
      }

      const newFavoriteActors = [...(userData.favoriteActors || []), detail];

      await updateDoc(userRef, {
        favoriteActors: newFavoriteActors
      });
    } catch (error) {
      console.error("Error adding favorite actor:", error);
    }
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 3000);
  };

  const profileImage = detail.profile_path
    ? `https://image.tmdb.org/t/p/original/${detail.profile_path}`
    : placeholderImage;

  return (
    <React.Fragment>
      <Helmet>
        <title>{detail.name ? `${detail.name} - MovieFrenzy` : "Acteur - MovieFrenzy"}</title>
        <link rel="canonical" href={`https://moviefrenzy.ca//ActeurDetails/${id}`} />
        <meta
          name="description"
          content={
            detail.name
              ? `${detail.name}, célèbre acteur connu pour son rôle dans plusieurs films. Découvrez sa biographie, ses détails personnels et plus sur MovieFrenzy.`
              : "Détails de l'acteur - Découvrez toutes les informations sur cet acteur célèbre sur MovieFrenzy."
          }
        />
      </Helmet>
      <NavigationBar />
      <div
        className="actor-details-container"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "overlay",
          backgroundColor: "#200211",
          opacity: "0.9",
          overflowX: "hidden",
        }}
      >
        <div className="actor-details-card">
          <div className="upper-section">
            <div className="card-container">
              <div className="card">
                <div
                  className="card-face front"
                  style={{
                    backgroundImage: `url(${profileImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                  }}
                >
                </div>
                <div className="card-face back">
                  <div
                    className="cardFace_SubContainer"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      zIndex: '1000'
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <video autoPlay muted loop className="videoCard-background">
                      <source src={`${process.env.PUBLIC_URL}/images/Severalturquoiseflashes02.mp4`} type="video/mp4" />
                    </video>
                    {detail.external_ids && detail.external_ids.instagram_id ? (
                      <a
                        href={`https://www.instagram.com/${detail.external_ids.instagram_id}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ zIndex: '1000' }}
                      >
                        <img src={'https://zupimages.net/up/24/41/zwmc.png'} alt="Instagram" className="instagram-icon" />
                      </a>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#FFFFFF',
                        }}
                      >
                        <img src={'https://zupimages.net/up/24/41/zwmc.png'} alt="Instagram" className="instagram-icon" />
                        <p style={{ color: '#FFFFFF', marginTop: "1rem" }}>Pas de compte Instagram disponible.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isLoggedIn && (
              <div className="containButtonFavoriteActor">
                <div className="favoriteActorButton" onClick={addFavoriteActor}>
                  <img src={`${process.env.PUBLIC_URL}/images/actorFavoriteButton02.png`} alt="coeur" className="coeurActor" />
                </div>
                {showConfirmation && (
                  <h2 className="ActorAjoutTitre">Acteur ajouté à vos favoris !</h2>
                )}
              </div>
            )}
          </div>
          <div className="actor-info-container">
            <h1 className="actor-details-name">{detail.name}</h1>
            <div className="actor-social-media">
              <h4 className="actor-birthday">{detail.birthday}</h4>
              <p className="actor-place-of-birth">
                {detail.place_of_birth}
              </p>
              <div className="actor-biography">
                <h2>Biographie</h2>
                <p>{detail.biography}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actor-details-footer"
        style={{ maxWidth: '100%', overflowX: 'hidden', maxHeight: '100%', overflowY: 'hidden' }}
      >
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ActeurDetails;





