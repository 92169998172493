import React, { useState, useEffect } from 'react';
import NavigationBar from "../components/Navigation";
import Footer from "../components/Footer";
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebasedeconfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../styles/components/editSubjectForumPage.module.scss';


const EditSubjectForumPage = () => {
    const { subjectId } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

        // Modules pour ReactQuill
        const modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                ['link', 'image', 'video'],
                ['clean'],
            ],
        };

    // useEffect(() => {
    //     const fetchSubject = async () => {
    //         try {
    //             const subjectRef = doc(db, 'posts', subjectId);
    //             const subjectSnap = await getDoc(subjectRef);

    //             if (subjectSnap.exists()) {
    //                 const subjectData = subjectSnap.data();
    //                 setTitle(subjectData.title || '');
    //                 setContent(subjectData.content || '');
    //                 setLoading(false);
    //             } else {
    //                 setError('Le sujet demandé est introuvable.');
    //                 setLoading(false);
    //             }
    //         } catch (err) {
    //             setError('Une erreur est survenue lors de la récupération des données.');
    //             setLoading(false);
    //         }
    //     };

    //     fetchSubject();
    // }, [subjectId]);

    const fetchSubject = async () => {
        try {
            const subjectRef = doc(db, 'posts', subjectId);
            const subjectSnap = await getDoc(subjectRef);
    
            if (subjectSnap.exists()) {
                const subjectData = subjectSnap.data();
                setTitle(subjectData.title || '');
                setContent(subjectData.content || '');
                setLoading(false);
            } else {
                setError('Le sujet demandé est introuvable.');
                setLoading(false);
            }
        } catch (err) {
            setError('Une erreur est survenue lors de la récupération des données.');
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchSubject();
    }, [subjectId]);
    
    // const handleSave = async () => {
    //     // ...
    //     navigate(`/subject/${subjectId}`);
    //     fetchSubject();
    // };

    const handleSave = async () => {
        if (!title.trim() || !content.trim()) {
            setError('Le titre et le contenu sont requis.');
            return;
        }

        try {
            const subjectRef = doc(db, 'posts', subjectId);
            await updateDoc(subjectRef, {
                title,
                content,
                updatedAt: new Date(),
            });
            alert('Sujet mis à jour avec succès !');
            navigate(`/subject/${subjectId}`);
            fetchSubject();
            navigate(`/Forum`);
        } catch (err) {
            console.error('Erreur lors de la mise à jour du sujet :', err);
            setError('Une erreur est survenue lors de la mise à jour du sujet.');
        }
    };

    const handleCancel = () => {
        if (window.history.length > 2) {
            navigate(-1); // Retour à la page précédente si elle existe
        } else {
            navigate('/Forum'); // Redirection explicite si aucune page précédente
        }
    };


    if (loading) {
        return <div className={styles.loading}>Chargement...</div>;
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <div className={styles.editSubjectForumPage}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundBlendMode: "overlay",
                backgroundColor: "#200211",
                opacity: "0.9",
                overflowX: "hidden",
                overflowY: "auto",
                minHeight: "100vh",
            }}
        >
            <NavigationBar />
            <div className={styles.editSubjectForumPage_container}>
                <div className={styles.editSubjectForumPage_subcontainer}>
                    <div className={styles.header}>
                        <h1>Modifier le sujet</h1>
                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="title">Titre du sujet</label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className={styles.inputField}
                                placeholder="Entrez le titre du sujet..."
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="content">Contenu</label>
                            <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={setContent}
                                modules={modules}
                                placeholder="Rédigez votre message ici..."
                                className="quill-editor"
                            />
                        </div>
                        <div className={styles.actions}>
                            <button onClick={handleSave} className={styles.saveButton}>
                                Enregistrer
                            </button>
                            <button onClick={handleCancel} className={styles.cancelButton}>
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EditSubjectForumPage;
