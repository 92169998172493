import React, { useState, useEffect, useContext } from "react";
import NavigationBar from "../components/Navigation";
import { OnlineUsersContext } from "../OnlineUsersContext";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebasedeconfig";
import { UserContext } from "../UserContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/components/EditReply.scss";
import { ToastContainer } from 'react-toastify';


const EditReply = () => {
    const { postId, replyId } = useParams();
    const navigate = useNavigate();
    const [replyContent, setReplyContent] = useState("");
    const { userId } = useContext(UserContext);
    const { onlineUsers } = useContext(OnlineUsersContext);

    useEffect(() => {
        const fetchReply = async () => {
            try {
                const replyRef = doc(db, "posts", postId, "replies", replyId);
                const replySnap = await getDoc(replyRef);
                if (replySnap.exists()) {
                    const data = replySnap.data();
                    if (data.authorId !== userId) {
                        toast.error("Vous n'êtes pas autorisé à modifier cette réponse.");
                        navigate(-1); // Redirige à la page précédente
                    } else {
                        setReplyContent(data.content);
                    }
                } else {
                    toast.error("Réponse introuvable.");
                    navigate(-1);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de la réponse :", error);
                toast.error("Erreur lors de la récupération de la réponse.");
                navigate(-1);
            }
        };

        fetchReply();
    }, [postId, replyId, userId, navigate]);

    // Modules pour ReactQuill
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };


    const handleUpdateReply = async () => {
        try {
            const replyRef = doc(db, "posts", postId, "replies", replyId);
            await updateDoc(replyRef, {
                content: replyContent,
                updatedAt: new Date(),
            });
            toast.success("Réponse mise à jour avec succès !");
            navigate(`/subject/${postId}`);
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
            toast.error("Échec de la mise à jour de la réponse.");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="edit-reply-page"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                    minHeight: "100vh",
                }}
            >
                <NavigationBar />
                <div className="edit-reply-container"
                    style={{
                        marginBottom: "4.5rem",
                        marginTop: "4.5rem",
                        padding: "1.5rem",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "0.5rem",
                        boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)",
                        border: "2px solid #00FFFF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <h2>Modifier la réponse</h2>
                    <ReactQuill
                        theme="snow"
                        value={replyContent}
                        onChange={setReplyContent}
                        modules={modules}
                        placeholder="Rédigez votre message ici..."
                        className="quill-editor"
                    />
                    <br />
                    <div className="button-container"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                        }}>
                        <button onClick={handleUpdateReply} className="saveEditReply-btn">Sauvegarder</button>
                        <button onClick={() => navigate(-1)} className="cancelEditReply-btn">Annuler</button>
                    </div>
                </div>
                <Footer />
            </div>
        </>


    );
};

export default EditReply;
