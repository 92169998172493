import React, { createContext, useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from './config/firebasedeconfig';

export const OnlineUsersContext = createContext();

export const OnlineUsersProvider = ({ children }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);

  const refreshOnlineUsers = async () => {
      const q = query(collection(db, "users"), where("isOnline", "==", true));
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
      }));
      setOnlineUsers(users);
      console.log("Manuel : Rafraîchissement des utilisateurs en ligne :", users);
  };

  useEffect(() => {
    const q = query(collection(db, "users"), where("isOnline", "==", true));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const users = snapshot.docs.map((doc) => ({
            uid: doc.id,
            ...doc.data(),
        }));
        setOnlineUsers(users);
    });
    return () => unsubscribe();
}, []);

  return (
      <OnlineUsersContext.Provider value={{ onlineUsers, refreshOnlineUsers }}>
          {children}
      </OnlineUsersContext.Provider>
  );
};

export default OnlineUsersProvider;












