import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import CircularNicknameSvg from '../components/CircularNicknameSvg';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, auth } from '../config/firebasedeconfig';
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { UserContext } from '../UserContext';
import { defaultBannerUrl, defaultAvatarUrl } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import SearchMovieForEdit from "../components/SearchMovieForEdit";
import styles from '../styles/components/EditProfil.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
// import 'quill/dist/quill.snow.css';
import he from 'he';
// import { countries as countryData } from 'countries-list';
import { Country, State, City } from 'country-state-city';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import PreviewMyFavoritesMovies from '../components/PreviewMyFavoritesMovies';
import MyFavoritesActors from '../components/MyFavoritesActors';
import My3FavoriteCategories from '../components/My3FavoriteCategories';
import PreviewMovie from '../components/PreviewMovie';
import { RefreshContext } from '../components/RefreshContext';
import Movie from '../components/Movie';
import EditTransition from '../components/EditTransition';
import { Helmet } from "react-helmet"; // Ajout de Helmet pour le SEO

const EditProfil = () => {

    const { refreshNeeded, setRefreshNeeded } = useContext(RefreshContext);
    const [biography, setBiography] = useState("");
    const [quote, setQuote] = useState("");
    const [favoriteCategories, setFavoriteCategories] = useState([]);
    const [favoriteMovies, setFavoriteMovies] = useState([]);
    const [favoriteActors, setFavoriteActors] = useState([]);
    const [favoritePreviews, setFavoritePreviews] = useState([]);


    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [zodiacSign, setZodiacSign] = useState("");
    const [occupation, setOccupation] = useState("");


    const countries = Country.getAllCountries();
    // const states = country ? State.getStatesOfCountry(country.isoCode) : [];
    const states = country ? State.getStatesOfCountry(country) : [];
    console.log("Available states for the selected country:", states);
    const cities = state ? City.getCitiesOfState(country, state) : [];
    console.log("Available cities for the selected state:", cities);

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredId, setIsHoveredId] = useState(null);
    const [isSaveClicked, setIsSaveClicked] = useState(false);

    const TTL = 24 * 60 * 60 * 1000;

    const history = useNavigate();

    // Récupérez l'ID de l'utilisateur depuis l'authentification Firebase
    const { userId } = useParams();

    const userRef = doc(getFirestore(), "users", userId);

    const handleSave = async () => {
        setIsSaveClicked(true);
        if (!auth.currentUser) {
            console.error("Aucun utilisateur n'est connecté.");
            history('/connexion');
            return;
        }

        try {
            const cleanedBiography = he.decode(biography);

            await updateDoc(userRef, {
                biography: cleanedBiography,
                quote: quote,
                favoriteCategories: favoriteCategories,
                favoriteMovies: favoriteMovies,
                favoriteActors: favoriteActors,
                gender: gender,
                age: age,
                zodiacSign: zodiacSign,
                occupation: occupation,
                country: country,
                state: state,
                city: city,
                favoriteMovie: selectedMovie,
                favoritePreviews: favoritePreviews,
                PreviewMovie: selectedMovie,

            });

            history(`/profilMemberPage/${userId}?showAnimation=true`);
        } catch (error) {
            console.error("Erreur lors de la mise à jour du profil:", error);
        }
        history(`/profilMemberPage/${userId}?showAnimation=true`);
    };


    const [selectedMovie, setSelectedMovie] = useState(null);

    const handleMovieSelected = (movie) => {
        setSelectedMovie(movie);
        // Ici, vous pouvez également stocker l'URL du preview YouTube ou d'autres informations pertinentes.
    };

    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key);
        // Si l'item n'existe pas, retourner null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // Comparer la date d'expiration avec la date actuelle
        if (now.getTime() > item.expiry) {
            // Si l'item est expiré, le supprimer du stockage et retourner null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }


    function setWithExpiry(key, value, ttl) {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };
        localStorage.setItem(key, JSON.stringify(item));
    }


    // Appelé lorsqu'un pays est sélectionné
    const handleCountryChange = (selectedCountryCode) => {
        setCountry(selectedCountryCode);
        setState(''); // Réinitialiser l'état
        setCity('');  // Réinitialiser la ville

        // Charger les états pour le nouveau pays sélectionné
        const cacheKey = `states_${selectedCountryCode}`;
        const cachedStates = getWithExpiry(cacheKey);
        console.log("Récupération du stockage local : " + cachedStates);
        if (!cachedStates) {
            const loadedStates = State.getStatesOfCountry(selectedCountryCode);
            setWithExpiry(cacheKey, loadedStates, TTL);
            setState(loadedStates);
        } else {
            setState(cachedStates);
        }
    };

    // Appelé lorsqu'un état est sélectionné
    const handleStateChange = (selectedStateCode) => {
        setState(selectedStateCode);
        setCity(''); // Réinitialiser la ville

        // Charger les villes pour le nouvel état sélectionné
        const cacheKey = `cities_${country}_${selectedStateCode}`;
        const cachedCities = getWithExpiry(cacheKey);
        if (!cachedCities) {
            const loadedCities = City.getCitiesOfState(country, selectedStateCode);
            setWithExpiry(cacheKey, loadedCities, TTL);
            setCity(loadedCities);
        } else {
            setCity(cachedCities);
        }
    };



    useEffect(() => {
        console.log("Country has changed:", country);
        console.log("States:", states);
    }, [country]);

    useEffect(() => {
        console.log("State has changed:", state);
        console.log("Cities:", cities);
    }, [state]);

    useEffect(() => {
        console.log("City has changed:", city);
    }, [city]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userSnap = await getDoc(userRef);
                const userData = userSnap.data();
                console.log("UserData from Firestore:", userData);

                if (!userSnap.exists()) {
                    // Initialisation aux premières valeurs par défaut en cas d'absence de données
                    const defaultData = {
                        biography: "",
                        quote: "",
                        favoriteCategories: [],
                        favoriteMovies: [],
                        favoriteActors: [],
                        favoritePreviews: [],
                        selectedMovie: null,
                        gender: "other",
                        age: "",
                        occupation: "other",
                        zodiacSign: "",
                        country: 'Choix du pays',
                        state: 'Choix de l\'état ou de la province',
                        city: 'Choix de la ville',
                        favoriteMovie: null
                    };

                    setBiography(defaultData.biography);
                    setQuote(defaultData.quote);
                    setFavoriteCategories(defaultData.favoriteCategories);
                    setFavoriteMovies(defaultData.favoriteMovies);
                    setFavoriteActors(defaultData.favoriteActors);
                    setGender(defaultData.gender);
                    setAge(defaultData.age);
                    setOccupation(defaultData.occupation);
                    setZodiacSign(defaultData.zodiacSign);
                    setCountry(defaultData.country);
                    setState(defaultData.state);
                    setCity(defaultData.city);
                    setSelectedMovie(defaultData.favoriteMovie);
                    setFavoritePreviews(defaultData.favoritePreviews);
                    // Mise à jour Firestore avec les données par défaut
                    await updateDoc(userRef, defaultData);
                    return;
                }

                // Utiliser les données récupérées de Firestore
                // Si la donnée est undefined, utilisez une valeur par défaut
                setBiography(userData.biography || "");
                setQuote(userData.quote || "");
                setFavoriteCategories(userData.favoriteCategories || []);
                setFavoriteMovies(userData.favoriteMovies || []);
                setFavoriteActors(userData.favoriteActors || []);
                setFavoritePreviews(userData.favoritePreviews || []);
                setGender(userData.gender || "other");
                setAge(userData.age || "");
                setOccupation(userData.occupation || "other");
                setZodiacSign(userData.zodiacSign || "");
                setCountry(userData?.country ?? 'Choix du pays');
                setState(userData?.state ?? 'Choix de l\'état ou de la province');
                setCity(userData?.city ?? 'Choix de la ville');
                setSelectedMovie(userData.favoriteMovie || null);

            } catch (error) {
                console.error("Erreur lors de la récupération des détails de l'utilisateur:", error);
            }
        };

        fetchUserDetails();
    }, []);

    const [allCountries, setAllCountries] = useState([]);

    useEffect(() => {
        setAllCountries(Country.getAllCountries());
    }, []);



    useEffect(() => {
        console.log("Initialized Country:", country);
    }, [country]);

    useEffect(() => {
        console.log("Initialized State:", state);
    }, [state]);

    useEffect(() => {
        console.log("Initialized City:", city);
    }, [city]);


    useEffect(() => {
        console.log('Biography a changé:', biography);
    }, [biography]);

    const handleDeleteAccount = async () => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.')) {
            try {
                // Supprimer le document Firestore associé à l'utilisateur
                await updateDoc(userRef, { /* mettre ici les champs à supprimer ou à mettre à jour */ });

                // Supprimer le compte utilisateur de Firebase Auth
                const users = auth.currentUser;
                await users.delete();

                // Rediriger vers la page d'accueil ou de connexion
                history('/');
            } catch (error) {
                console.error("Erreur lors de la suppression du compte:", error);
            }
        }
    };

    const handleDeleteFavorite = async (type, id) => {
        let updatedFavorites = [];
        switch (type) {
            case 'movie':
                updatedFavorites = favoritePreviews.filter(movie => movie.id !== id);
                setFavoritePreviews(updatedFavorites);
                break;
            case 'actor':
                updatedFavorites = favoriteActors.filter(actor => actor.id !== id);
                setFavoriteActors(updatedFavorites);
                break;
            case 'category':
                updatedFavorites = favoriteCategories.filter(category => category.id !== id);
                setFavoriteCategories(updatedFavorites);
                break;
            default:
                console.error("Type de favori inconnu");
                return;
        }

        // Mettre à jour Firestore
        try {
            await updateDoc(userRef, {
                [`${type}s`]: updatedFavorites
            });
        } catch (error) {
            console.error(`Erreur lors de la suppression du favori de type ${type} avec l'id ${id}:`, error);
        }
        // Mettre à jour l'état pour forcer un rafraîchissement dans ProfilMember.js
        setRefreshNeeded(true);
    };


    // Au montage du composant
    useEffect(() => {
        const cacheKey = 'countries';
        const cachedCountries = getWithExpiry(cacheKey);
        if (!cachedCountries) {
            const loadedCountries = Country.getAllCountries();
            setWithExpiry(cacheKey, loadedCountries, TTL);
            setAllCountries(loadedCountries);
        } else {
            setAllCountries(cachedCountries);
        }

    }, []);





    console.log("favoritePreviews dans EditProfil.js", favoritePreviews);
    console.log("favoriteMovies dans EditProfil.js", favoriteMovies);

    return (
        <>
            {/* Helmet pour le SEO */}
            <Helmet>
                <title>Éditer votre profil - MovieFrenzy</title>
                <meta name="description" content="Personnalisez votre profil sur MovieFrenzy. Ajoutez votre biographie, vos films favoris, et plus encore pour créer un profil unique !" />
                <meta name="keywords" content="Éditer profil, MovieFrenzy, films favoris, acteurs préférés, biographie" />
                <meta property="og:title" content="Éditez votre profil sur MovieFrenzy" />
                <meta property="og:description" content="Ajoutez votre biographie, sélectionnez vos films favoris, et personnalisez votre profil cinéphile." />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/profil-placeholder.jpg`} />
                <meta property="og:url" content={`https://moviefrenzy.ca/edit-profile/${userId}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Éditez votre profil - MovieFrenzy" />
                <meta name="twitter:description" content="Personnalisez votre profil cinéphile et partagez vos films préférés avec la communauté MovieFrenzy !" />
            </Helmet>
            <Navigation />
            <div className="EditProfil"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "block",
                    zIndex: "1",
                }}
            >
                <div className={styles.container}>
                    <form className="form-group">
                        <h2>Édition de Profil</h2>
                        <label>Biographie:</label>
                        <ReactQuill value={biography} onChange={setBiography} style={{ width: '100%' }} />

                        <label>Citation:</label>
                        <input type="text" value={quote} onChange={(e) => setQuote(e.target.value)} />

                        <label>Gestion de mes préférences cinéphiles:</label>
                        <div className="row gestionFavoriteContainer">
                            <h4>Suppression de mes films préférés :</h4>
                            <div className="gestionFavorite">
                                {favoritePreviews.map(movie => (
                                    <div key={movie.id} className='containerButtonFavorie-1'>
                                        <PreviewMyFavoritesMovies movie={movie} isHoveredId={isHoveredId} />
                                        <button onClick={() => handleDeleteFavorite('movie', movie.id)} className="buttonCloseFavorite0"
                                            onMouseEnter={() => setIsHoveredId(movie.id)}
                                            onMouseLeave={() => setIsHoveredId(null)}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.75rem',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <h4>Suppression de mes acteurs préférés :</h4>
                            <div className="gestionFavorite">
                                {favoriteActors.map(actor => (
                                    <div key={actor.id} className='containerButtonFavorie-1'>
                                        <MyFavoritesActors actor={actor} isHoveredId={isHoveredId} />
                                        <button onClick={() => handleDeleteFavorite('actor', actor.id)} className="buttonCloseFavorite1"
                                            onMouseEnter={() => setIsHoveredId(actor.id)}
                                            onMouseLeave={() => setIsHoveredId(null)}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.75rem',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <h4>Suppression de mes trois genres préférés :</h4>
                            <div className="gestionFavorite_B">
                                {favoriteCategories.map(category => (
                                    <div key={category.id}
                                        className='containerButtonFavorie-2'
                                        style={{
                                            padding: '0 2vw',
                                        }}
                                    >
                                        <My3FavoriteCategories category={category} />
                                        <button onClick={() => handleDeleteFavorite('category', category.id)} className="buttonCloseFavorite2"
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            id='buttonCloseFavorite2'
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.75rem',
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} className="FontAwesomeIconClass" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <label>Sexe/Genre:</label>
                        <select value={gender} onChange={(e) => setGender(e.target.value)}>
                            <option value="other">Autre</option>
                            <option value="male">Homme</option>
                            <option value="female">Femme</option>
                            <option value="nonbinary">Non binaire</option>
                            <option value="preferNotToSay">Je préfère ne pas répondre</option>
                        </select>

                        <label>Âge:</label>
                        <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />
                        <label>Signe astrologique:</label>
                        <select value={zodiacSign} onChange={(e) => setZodiacSign(e.target.value)}>
                            <option value="aquarius" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/xucf.png)`, backgroundSize: '32px 32px' }}>Verseau</option>
                            <option value="aries" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/t314.png)`, backgroundSize: '32px 32px' }}>Bélier</option>
                            <option value="capricorn" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/j92j.png)`, backgroundSize: '32px 32px' }}>Capricorne</option>
                            <option value="cancer" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/2xbb.png)`, backgroundSize: '32px 32px' }}>Cancer</option>
                            <option value="gemini" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/6xwq.png)`, backgroundSize: '32px 32px' }}>Gémeaux</option>
                            <option value="leo" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/0ilk.png)`, backgroundSize: '32px 32px' }}>Lion</option>
                            <option value="libra" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/yeo8.png)`, backgroundSize: '32px 32px' }}>Balance</option>
                            <option value="pisces" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/jlxa.png)`, backgroundSize: '32px 32px' }}>Poissons</option>
                            <option value="sagittarius" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/g5m1.png)`, backgroundSize: '32px 32px' }}>Sagittaire</option>
                            <option value="scorpio" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/wiyy.png)`, backgroundSize: '32px 32px' }}>Scorpion</option>
                            <option value="taurus" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/50i8.png)`, backgroundSize: '32px 32px' }}>Taureau</option>
                            <option value="virgo" style={{ backgroundImage: `url(https://zupimages.net/up/23/41/zmar.png)`, backgroundSize: '32px 32px' }}>Vierge</option>
                        </select>
                        <label>Occupation:</label>
                        <select value={occupation} onChange={(e) => setOccupation(e.target.value)}>
                            <option value="student">Étudiant</option>
                            <option value="employed">Employé</option>
                            <option value="unemployed">Chômeur</option>
                            <option value="retired">Retraité</option>
                            <option value="other">Autre</option>
                            <option value="preferNotToSay">Je préfère ne pas répondre</option>
                        </select>


                        <label>Pays:</label>
                        <select onChange={(e) => handleCountryChange(e.target.value)}>
                            {allCountries.map(c => {
                                // console.log("Option de pays :", c.isoCode);  // Ajout du console.log
                                return <option key={c.isoCode} value={c.isoCode}>{c.name}</option>;
                            })}
                        </select>
                        <label>États ou Provinces:</label>
                        {states.length > 0 && (
                            <select onChange={(e) => setState(e.target.value)}>
                                {states.map(s => {
                                    // console.log("Option d'état :", s.isoCode);  // Ajout du console.log
                                    return <option key={s.isoCode} value={s.isoCode}>{s.name}</option>;
                                })}
                            </select>
                        )}
                        <label>Villes:</label>
                        {cities.length > 0 && (
                            <select onChange={(e) => setCity(e.target.value)}>
                                {cities.map(c => {
                                    // console.log("Option de ville :", c.name);  // Ajout du console.log
                                    return <option key={c.name} value={c.name}>{c.name}</option>;
                                })}
                            </select>
                        )}
                        <label>Ma preview coup de coeur:</label>
                        <div className="searchMovieContainer">
                            <SearchMovieForEdit onMovieSelected={handleMovieSelected} />
                        </div>
                        <button type="button" onClick={handleSave} className='btnEditProfil'>Enregistrer</button>
                    </form>
                </div>
            </div>
            <div className="actor-details-footer"
                style={{ maxWidth: '100%', overflowX: 'hidden', maxHeight: '100%', overflowY: 'hidden' }}
            >
                <Footer />
            </div>
        </>

    );

};
export default EditProfil;







