import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useLocation, Link, useMatch } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../styles/components/Navigation.scss';
import { UserContext } from '../UserContext';
import { auth, storage } from '../config/firebasedeconfig';
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { defaultAvatarUrl, defaultUsername, defaultEmail } from '../constants';
import { signOut } from "firebase/auth";
import { OnlineUsersContext } from '../OnlineUsersContext';
import OnlineUsersSidebar from '../onlinelateralusers';
import { RefreshContext } from '../components/RefreshContext';
import { RefreshProvider } from '../components/RefreshContext';

// Create a storage reference from our storage service
const storageRef = ref(storage);
const db = getFirestore();

const NavigationBar = () => {

    const history = useNavigate();
    const { refreshNeeded, setRefreshNeeded } = useContext(RefreshContext);
    const { userId } = useContext(UserContext);

    const navigateToProfilMember = () => {
        const newUrl = history(`/profilMemberPage/${userId}?showAnimation=true`);  // Adjust as needed

    };

    const location = useLocation();
    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const { username, email, avatar, isLoggedIn, resetContext, setUsername, setEmail, setAvatar, setIsLoggedIn } = useContext(UserContext);
    const userIsLoggedIn = username !== null && username !== "";
    const [isSidebarOpen, setSidebarOpen] = useState(false); // Nouvel état pour la barre latérale
    const onlineUsers = useContext(OnlineUsersContext); // Récupère les utilisateurs en ligne depuis le contexte




    // Function to open the sidebar
    const openSidebar = () => {
        setSidebarOpen(true);
    };

    // Function to close the sidebar
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    // console.log("Test de Username:", username);
    // console.log("Test de Email:", email);
    // console.log("Test de Avatar:", avatar);
    // console.log("Test de isLoggedIn:", isLoggedIn);
    // console.log("Test de userIsLoggedIn:", userIsLoggedIn);


    useEffect(() => {
        console.log("Debug username from ProfilMember:", username); // Affiche le nom d'utilisateur dans la console
        const fetchData = async () => {
            try {
                if (auth.currentUser) {
                    const userDoc = doc(db, 'users', auth.currentUser.uid);
                    const docSnap = await getDoc(userDoc);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setAvatar(data.avatar ? data.avatar : defaultAvatarUrl);
                        setUsername(data.username ? data.username : defaultUsername);
                        setEmail(data.email ? data.email : defaultEmail);
                    }
                }

            } catch (error) {
                console.error("Une erreur est survenue lors de la récupération des données :", error);
                // Vous pouvez également définir un état d'erreur ici si vous le souhaitez
            }
        };

        fetchData();
    }, [userId, refreshNeeded, setRefreshNeeded, username, email, avatar, isLoggedIn, resetContext, setUsername, setEmail, setAvatar]);


    // Function to handle sign out
    const handleSignOut = () => {
        auth.signOut()
            .then(() => {
                console.log("User signed out");
                resetContext();  // Utilisez la fonction ici
                history("/");
            })
            .catch((error) => {
                console.log(error);
            });
    };




    const handleCategoryClick = (id, name) => {
        setSelectedCategoryName(name);
        history(`/categories/${id}`);
    };

    const handleSelectCategoriesClick = () => {
        setSelectedCategoryName("");
        history('/categories');
    };

    // Get the current category name from the URL path
    const getCurrentCategoryName = () => {
        const path = location.pathname;
        const parts = path.split('/');
        if (parts.length >= 3) {
            return parts[2];
        }
        return "";
    };

    useEffect(() => {
        const categoryName = getCurrentCategoryName();
        setSelectedCategoryName(categoryName);
    }, [location.pathname]);

    const {
        setIsLoggedIn: fetchUserData, updateContext
    } = useContext(UserContext);

    // Fonction pour gérer la déconnexion


    const handleLogout = async () => {
        const user = auth.currentUser; // Vérifie l'utilisateur actuellement connecté
        if (user) {
          try {
            // Étape 1 : Mettre à jour Firestore AVANT de déconnecter
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, { isOnline: false });
            console.log("Statut isOnline mis à jour dans Firestore : false");
      
            // Étape 2 : Déconnecter l'utilisateur
            await signOut(auth);
            console.log("Utilisateur déconnecté avec succès.");
      
            // Étape 3 : Réinitialiser les états locaux et rediriger
            updateContext(null, null, null, false);
            history('/'); // Redirige vers la page d'accueil après déconnexion
          } catch (error) {
            console.error("Erreur lors de la déconnexion :", error);
          }
        } else {
          console.warn("Aucun utilisateur connecté pour effectuer la déconnexion.");
        }
      };
      

    const CustomNavLink = ({ to, children }) => {
        // useMatch doit être utilisé à l'intérieur d'un composant fonctionnel
        const match = useMatch({
            path: to,
            end: false,
        });


        return (
            <Nav.Link
                as={NavLink}
                to={to}
                className={match ? "nav-active nav-link-custom font-weight-bolder mx-4 align-items-center" : "nav-link-custom font-weight-bolder mx-4 align-items-center"}
            >
                {children}
            </Nav.Link>
        );
    };



    return (
        <>
            <OnlineUsersSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
            <Navbar expand="lg" className={`navigation w-100 navbar-expand-lg ${location.pathname === '/Propos' ? 'bg-gradient' : 'bg-gradient'}`} >
                <Container className="align-items-center containerNav">
                    <NavLink to="/" className={({ isActive }) => (isActive ? "nav-active navbar-brand" : "navbar-brand")}>
                        <div className="logoNavbarContainer">
                            <img src={`${process.env.PUBLIC_URL}/images/colorLogoMovieFrenzyE.svg`} alt="logo" width="100" height="100" className="logoNavbar" />
                            <span className="slogan-Logo">
                                <h2 id="h2slogan-Logo">MOVIEFRENZY</h2>
                            </span>
                        </div>
                    </NavLink>
                    <div className="box-blink">
                        <p className="blinking-text">
                            <span data-letter="L">L</span>
                            <span data-letter="a">a</span>
                            <span data-letter=" "> </span>
                            <span data-letter="f">f</span>
                            <span data-letter="r">r</span>
                            <span data-letter="é">é</span>
                            <span data-letter="n">n</span>
                            <span data-letter="é">é</span>
                            <span data-letter="s">s</span>
                            <span data-letter="i">i</span>
                            <span data-letter="e">e</span>
                            <span data-letter=" "> </span>
                            <span data-letter="d">d</span>
                            <span data-letter="u">u</span>
                            <span data-letter=" "> </span>
                            <span data-letter="c">c</span>
                            <span data-letter="i">i</span>
                            <span data-letter="n">n</span>
                            <span data-letter="é">é</span>
                            <span data-letter="m">m</span>
                            <span data-letter="a">a</span>
                            <span data-letter=",">,</span>
                        </p>
                        <p className="blinking-text2">
                            <span data-letter="à">à</span>
                            <span data-letter=" "> </span>
                            <span data-letter="p">p</span>
                            <span data-letter="o">o</span>
                            <span data-letter="r">r</span>
                            <span data-letter="t">t</span>
                            <span data-letter="é">é</span>
                            <span data-letter="e">e</span>
                            <span data-letter=" "> </span>
                            <span data-letter="d">d</span>
                            <span data-letter="e">e</span>
                            <span data-letter=" "> </span>
                            <span data-letter="c">c</span>
                            <span data-letter="l">l</span>
                            <span data-letter="i">i</span>
                            <span data-letter="c">c</span>
                            <span data-letter=" "> </span>
                            <span data-letter="!">!</span>
                        </p>
                    </div>
                    <Navbar.Toggle aria-controls="navbarScroll">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto my-2 my-lg-0 justify-content-end align-items-center d-flex">
                            <Nav.Link as={NavLink} to="/" className={({ isActive }) => (isActive ? "nav-active nav-link-custom font-weight-bolder mx-4 align-items-center" : "nav-link-custom font-weight-bolder mx-4 align-items-center")}>Accueil</Nav.Link>
                            <NavDropdown title="Catégories" id="navbarScrollingDropdown" className="custom-dropdown-menu" style={{ fontSize: "1.4rem" }}>
                                <NavDropdown.Item
                                    onClick={handleSelectCategoriesClick}
                                    className="allCategorySelect"
                                >
                                    Sélectionnez vos catégories
                                </NavDropdown.Item>
                                <NavDropdown.Item className="dropdown-menu d-flex align-items-center">
                                    <Nav className="flex-wrap align-items-center">
                                        <Nav.Link onClick={() => handleCategoryClick(28, "Action")} className="dropdown-item">Action</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(16, "Animation")} className="dropdown-item">Animation</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(12, "Aventure")} className="dropdown-item">Aventure</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(35, "Comédie")} className="dropdown-item">Comédie</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(80, "Crime")} className="dropdown-item">Crime</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(99, "Documentaire")} className="dropdown-item">Documentaire</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(18, "Drame")} className="dropdown-item">Drame</Nav.Link>
                                    </Nav>
                                    <Nav className="flex-wrap align-items-center">
                                        <Nav.Link onClick={() => handleCategoryClick(10751, "Familial")} className="dropdown-item">Familial</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(14, "Fantastique")} className="dropdown-item">Fantastique</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(10752, "Guerre")} className="dropdown-item">Guerre</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(36, "Histoire")} className="dropdown-item">Histoire</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(27, "Horreur")} className="dropdown-item">Horreur</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(10402, "Musique")} className="dropdown-item">Musique</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(9648, "Mystère")} className="dropdown-item">Mystère</Nav.Link>
                                    </Nav>
                                    <Nav className="flex-wrap align-items-center">
                                        <Nav.Link onClick={() => handleCategoryClick(10749, "Romance")} className="dropdown-item">Romance</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(878, "Science-fiction")} className="dropdown-item">Science-fiction</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(53, "Thriller")} className="dropdown-item">Thriller</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(10770, "Téléfilm")} className="dropdown-item">Téléfilm</Nav.Link>
                                        <Nav.Link onClick={() => handleCategoryClick(37, "Western")} className="dropdown-item">Western</Nav.Link>
                                    </Nav>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <CustomNavLink to="/Propos">À Propos</CustomNavLink>
                            <Nav.Link as={NavLink} to="/recherche" className={({ isActive }) => (isActive ? "nav-active mx-3 align-items-center" : "mx-3 align-items-center")}>
                                <i className="fas fa-search icon custom-icon"></i>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/recherche" className={({ isActive }) => (isActive ? "nav-active mx-3 align-items-center" : "mx-3 align-items-center")}>
                                <i className="fas fa-language icon custom-icon"></i>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/recherche" className={({ isActive }) => (isActive ? "nav-active mx-3 align-items-center" : "mx-3 align-items-center")}>
                                <i className="fas fa-bell icon custom-icon"></i>
                            </Nav.Link>
                            <Nav.Item className="mx-3 align-items-center" >
                                <NavDropdown title={<>
                                    <span className="d-none d-lg-inline" style={userIsLoggedIn ? { backgroundColor: "#000", color: "#00FFFF", padding: "5px", border: "1px solid #00FFFF", borderRadius: "5px" } : {}}>{username || defaultUsername}</span>
                                    <span className="avatar3 me-2" style={userIsLoggedIn ? { border: "1px solid #00FFFF", marginTop: "1vh" } : {}}>
                                        {userIsLoggedIn ? (avatar ? <img src={avatar} alt="Avatar" /> : <img src={defaultAvatarUrl} alt="Default Avatar" />) : <i className="fas fa-user"></i>}
                                    </span>
                                </>} id="navbarScrollingDropdown2" className="custom-dropdown-menu">
                                    <NavDropdown.Header>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center me-3" style={{ border: `1px solid #FF33CC`, padding: `0.25rem` }}>
                                                <div className="avatar3a me-2">
                                                    {userIsLoggedIn ? (avatar ? <img src={avatar} alt="Avatar" /> : <img src={defaultAvatarUrl} alt="Default Avatar" />) : <i className="fas fa-user" style={{ color: '#D90093' }}></i>}
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            fontSize: "24px",
                                                            fontWeight: "400",
                                                            color: username ? "#00FFFF" : "#FFFFFF"
                                                        }}
                                                    >
                                                        {username || "Nom d'utilisateur"}
                                                    </div>
                                                    <div style={{ fontSize: "14px", color: "#ffffff" }}>{email || defaultEmail}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Header>
                                    <Nav.Item className="d-flex">
                                        <Nav className="flex-wrap">
                                            <NavDropdown.Item as={Link} to={auth.currentUser ? `/profilMemberPage/${userId}` : '/connexion'}>
                                                <i className="fas fa-user me-2"></i>Mon profil
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/creercompte">
                                                <i className="fas fa-cog me-2"></i>Création de compte
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={auth.currentUser ? `/EditProfilPage/${userId}` : '/connexion'}>
                                                <i className="fas fa-user me-2"></i>Gestion du profil
                                            </NavDropdown.Item>
                                            <NavDropdown.Item>
                                                <i className="fas fa-sliders-h me-2"></i>Paramètres
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={openSidebar}>
                                                <i className="fas fa-users me-2"></i> Qui est en ligne?
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/Forum">
                                                <i className="fas fa-comments me-2"></i>Forum
                                            </NavDropdown.Item> {/* Nouvelle entrée pour le forum */}
                                            <NavDropdown.Item>
                                                <i className="fas fa-question-circle me-2"></i>Centre d'aide
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                onClick={() => {
                                                    if (isLoggedIn) {
                                                        handleLogout();
                                                    } else {
                                                        history("/connexion"); // Redirection vers la page de connexion
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-sign-out-alt me-2"></i>
                                                {!isLoggedIn ? "Connexion" : "Déconnexion"}
                                            </NavDropdown.Item>
                                        </Nav>
                                    </Nav.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        </>
    );
};

export default NavigationBar;

