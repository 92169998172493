import React, { useContext, useState, useEffect } from 'react';
import { OnlineUsersContext } from './OnlineUsersContext';
import Modal from 'react-bootstrap/Modal';
import EmuleProfil from './components/EmuleProfil';
import styles from './styles/components/onlinelateralemule.scss';

const OnlineUsersSidebar = ({ isOpen, closeSidebar }) => {
  const { onlineUsers } = useContext(OnlineUsersContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className={`online-users-sidebar ${isOpen ? 'open' : ''}`} style={{ zIndex: '10000' }}>
      <button onClick={closeSidebar}>Fermer</button>
      {onlineUsers && onlineUsers.length > 0 ? (
        <ul style={{ margin: "0.5rem" }}>
          {onlineUsers.map((user) => (
            <li
              key={user.uid}
              onClick={() => handleUserClick(user)}
              style={{
                filter: hover
                  ? 'drop-shadow(0px 0px 7.5px #00FFFF) brightness(1.2)'
                  : 'brightness(1)',
                cursor: 'pointer',
              }}              
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={user.avatar} alt={`${user.username}'s avatar`} />
              <span>{user.username}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ margin: "1rem", fontSize: "0.75rem", color: "#00ffff", textAlign: "center", backgroundColor: "#200211", padding: "0.5rem", maxWidth: "clamp(300px, 50%, 500px)" }}>Aucun utilisateur en ligne</p>
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modalProfil"
        style={{ opacity: '100%', width: '100%', minHeight: '100%', height: '100%' }}
      >
        <Modal.Dialog
          id="custom-modal"
          style={{
            maxWidth: '70vw',
            height: '100%',
            maxHeight: '100%',
            backgroundColor: '#00FFFF',
            border: '3px solid #FFFFFF',
            boxShadow: '8px 4px 4px #00FFFF',
            overflowY: 'hidden',
          }}
        >
          <Modal.Header style={{ color: '#D90093', backgroundColor: '#000', display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ color: '#D90093', float: 'left' }}><Modal.Title>{selectedUser ? selectedUser.username : ''}</Modal.Title></span>
            <span style={{ color: '#00FFFF', float: 'right' }}><Modal.Title>{selectedUser ? selectedUser.occupation : ''}</Modal.Title></span>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#200211' }}>
            <EmuleProfil selectedUser={selectedUser} />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </div>
  );
};

export default OnlineUsersSidebar;








