import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../styles/components/Slider.scss";
import "../styles/components/MovieSection.scss";
import Genre from './Genre';
import { WaveMovie } from "./WaveMovie";


const MovieSection = ({ movie, handleActorDetailsClick }) => {
  const [credits, setCredits] = useState([]);
  const [genres, setGenres] = useState([]);
  const history = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseClick = () => {
    history(`/ficheFilm/${movie.id}`);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleCategoryClick = (id, name, fromNavigation = false) => {
    history({
      pathname: `/categories/${id}`,
      state: { id: id, name: name, fromNavigation: fromNavigation },
    });
  };

  const handleClick = (actorId) => {
    handleActorDetailsClick(actorId);
  };

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const creditsResponse = await axios.get(
          `https://api.themoviedb.org/3/movie/${movie.id}/credits?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
        );
        setCredits(creditsResponse.data.cast);

        const genresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/movie/list?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
        );
        setGenres(genresResponse.data.genres);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMovieDetails();
  }, [movie.id]);

  const { title, adult, backdrop_path, overview, id, poster_path } = movie;


  const renderGenres = (genreIds) => {
    return genreIds.map((id) => {
      const genre = genres.find((g) => g.id === id);
      if (genre) {
        return (
          <Genre
            key={id}
            id={genre.id}
            name={genre.name}
            onClick={() => handleCategoryClick(genre.id, genre.name, true)}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
        );
      }
      return null;
    });
  };


  const publicPercent = Math.floor(movie.vote_average * 1000) / 100;
  const yearDate = movie.release_date;
  const lengthTimeMovie = movie.runtime;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-reveal');
        }
      });
    }, {
      threshold: 0.5
    });

    const observerButtons = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-translateButton');
        }
      });
    }, {
      threshold: 0.5
    });

    const titles = document.querySelectorAll('.aHrefMovieSectionA > h1');
    titles.forEach(el => observer.observe(el));

    const buttons = document.querySelectorAll('#trailerButton, .lectureButton, .ageButton');
    buttons.forEach(el => observerButtons.observe(el));

    // Cleanup function
    return () => {
      titles.forEach(el => observer.unobserve(el));
      buttons.forEach(el => observerButtons.unobserve(el));
    };
  }, []); // Les crochets vides indiquent que cet effet ne s'exécute qu'au montage



  return (
    <section className="movieGabarit">
      <div className="container">
        <div className="row">
          <div className="containerGenre-fluid-pc">
            <div className="categorieDivMovie">
              <span>{renderGenres(movie.genre_ids)}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 mb-md-0 move-up" style={{ zIndex: "1000" }}>
            <Link to={`/ficheFilm/${movie.id}`} className="aHrefMovieSectionA title-link">
              <h1 className="titreFilm">{title}</h1>
            </Link>
            <div className="ratingPercent">
              <div className="textContainer">
                <div className="publicpercent">
                  <div>Satisfaction du public :</div>
                  <span className="spanPercent">{publicPercent}%</span>
                </div>
                <div className="dateContainer"
                  style={{ zIndex: "1000", position: "relative", top: "0", left: "5vw", width: "100%", display: "flex", justifyContent: "space-between", color: "#FF33CC", fontSize: "1.5rem" }}
                >
                  {yearDate}. {lengthTimeMovie}
                </div>
              </div>
            </div>
            <div
              className="bteSynopsis"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                position: "relative",
                backgroundColor: isHovered ? "rgba(255, 255, 255, 0.125)" : "rgba(255, 51, 204, 1)",
                color: isHovered ? "#00FFFF" : "#FFFFFF",
                border: isHovered ? "1px solid #00FFFF" : "1px solid #FFFFFF",
                transition: "background-color 0.3s ease, color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease, filter 0.3s ease, -webkit-filter 0.3s ease !important",
                zIndex: "1000",
                fontStyle: isHovered ? "italic" : "normal",
                transform: isHovered ? "drop-shadow(0 0 10px #00FFFF)" : "none !important",
                boxShadow: isHovered ? "0 4px 10px #00FFFF, inset 0 0 15px #FF33CC" : "none",
              }}
            >
              <p>{overview}</p>
            </div>
          </div>
          <div className="col-12 col-md-5 offset-md-1 buttonSectionSlider">
            <div className="d-grid gap-3 buttonMovieSection mx-auto col-md-3 align-items-center" >
              <Link to={`/ficheFilm/${movie.id}`} className="lectureButton d-flex grosBouton">
                <button className="button-3D-effect">
                  <span className="button-text lecturePlay">&#9658; LECTURE</span>
                </button>
              </Link>
              <Link to={`/ficheFilm/${movie.id}`} className="btn btn-dark btn-lg" id="trailerButton">BANDE-ANNONCE</Link>
              <Link to={`/ficheFilm/${movie.id}`} className="aHrefMovieSectionA title-link magie">
                <h1 className="titreFilm">{title}</h1>
              </Link>
              {movie.adult ? (
                <button type="button" className="btn ageButton">Réservé aux adultes</button>
              ) : (
                <button type="button" className="btn ageButton">Tous publics</button>
              )}
            </div>
            <div className="waveRiveMovie01"><WaveMovie /></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="gabarit_distribution col-md-4">
              <div className="distribution">Distribution:</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 wrapActor">
            <div className="movie-actors">
              <h5>Acteurs:</h5>
              <div className="row actorRowSlider">
                {credits && credits.slice(0, 4).map((credit, index) => (
                  <div key={index} className="actor-col col-lg-3">
                    <Link className="no-underline" to={`/acteurDetails/${credit.id}`}>
                      <img
                        src={credit.profile_path ? `https://image.tmdb.org/t/p/original/${credit.profile_path}` : credit.poster_path ? `https://image.tmdb.org/t/p/original/${credit.poster_path}` : "https://zupimages.net/up/23/14/zdoe.jpg"}
                        alt={`Actor ${index + 1}`}
                        className={`actor-image img-fluid${index === 3 ? ' mainActors-image4' : ''}`}
                        onClick={() => handleClick(credit.id)}
                      />
                      <div className="actor-name-container">
                        <span className="actor-name">{credit.original_name}</span>
                        <span className="actor-character">{credit.character}</span>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

const Slider = () => {
  const [popular, setPopular] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [hovered, setHovered] = useState(false); // Initialiser hovered avec useState
  const [direction, setDirection] = useState('left'); // pour suivre la direction
  const history = useNavigate();

  useEffect(() => {
    axios
      .get(
        "https://api.themoviedb.org/3/movie/popular?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR&page=1"
      )
      .then((response) => {
        setPopular(response.data.results.slice(0, 6));
      })
      .catch((error) => console.log(error));
  }, []);

  const handleActorDetailsClick = (actorId) => {
    history(`/acteurDetails/${actorId}`);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === popular.length - 1 ? 0 : activeIndex + 1;
    setDirection('right');
    setActiveIndex(nextIndex);
    setAnimating(true);
    setTimeout(() => setAnimating(false), 1000); // 1s pour synchroniser avec l'animation
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? popular.length - 1 : activeIndex - 1;
    setDirection('left');
    setActiveIndex(nextIndex);
    setAnimating(true);
    setTimeout(() => setAnimating(false), 1000); // 1s pour synchroniser avec l'animation
  };

  const slides = popular.map((p, index) => (
    <CarouselItem
      key={index}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      className={`carousel-item${index === activeIndex ? " active" : ""} c-item`}
      onMouseEnter={() => setHovered(true)} // Activer hovered sur le survol
      onMouseLeave={() => setHovered(false)} // Désactiver hovered à la sortie du survol
    >
      <img
        src={`https://image.tmdb.org/t/p/original/${p.backdrop_path}`}
        alt={`Slide ${index + 1}`}
        className="c-img img-fluid"
        style={{
          backgroundColor: "rgba(255,51,204,0.24)",
          width: "100%",
          height: "100%",
          objectFit: "contain"
        }}
      />
      <div
        className={`carousel-title ${hovered ? (direction === "left" ? "title-animate-in-left" : "title-animate-in-right")
          : (direction === "left" ? "title-animate-out-left" : "title-animate-out-right")
          }`}
      >
        {p.title}
      </div>
    </CarouselItem>
  ));

  return (
    <div id="carouselExampleFade" className="carousel slide carousel-fade carousel-wrapper">
      {popular.length > 0 && (
        <>
          <div className="carousel-inner">
            {slides}
            <div className="carousel-indicators">
              {popular.map((p, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExampleFade"
                  data-bs-slide-to={index}
                  className={index === activeIndex ? "active" : ""}
                  aria-current={index === activeIndex ? "true" : ""}
                  aria-label={`Slide ${index + 1}`}
                  style={{
                    filter: index === activeIndex ? "drop-shadow(0px 0px 10px #00FFFF)" : "none"
                  }}
                />
              ))}
            </div>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
              className="carousel-control-middle"
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
              className="carousel-control-middle"
            />
          </div>
          <MovieSection movie={popular[activeIndex]} handleActorDetailsClick={handleActorDetailsClick} />
        </>
      )}
    </div>
  );
};

export default Slider;


