import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "../styles/components/CreatePostModal.scss";
import { db } from "../config/firebasedeconfig";
import { UserContext } from "../UserContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { clamp } from "three/src/math/MathUtils.js";


const CreatePostModal = ({ isOpen, onClose, fetchTopics }) => {
    const navigate = useNavigate(); // Initialisez navigate
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [content, setContent] = useState("");
    const [error, setError] = useState("");

    const categories = ["Action", "Romance", "Horreur", "Nouveautés", "Classiques"];

    // Récupère l'auteur et l'ID utilisateur depuis le UserContext
    const { username, userId } = useContext(UserContext);

    // Configuration de l'éditeur de texte
    // const modules = {
    //     toolbar: [
    //         [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    //         [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //         [{ 'color': [] }, { 'background': [] }],
    //         [{ 'align': [] }],
    //         ['link', 'image', 'video'],
    //         ['clean'],
    //     ],
    // };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
            ['formula'],
        ],
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!title || !category || !content) {
    //         setError("Tous les champs sont obligatoires.");
    //         return;
    //     }

    //     try {
    //         await addDoc(collection(db, "posts"), {
    //             title,
    //             category,
    //             content,
    //             timestamp: serverTimestamp(),
    //             author: username || "Anonyme",
    //             authorId: userId,
    //         });

    //         setTitle("");
    //         setCategory("");
    //         setContent("");
    //         setError("");
    //         onClose();
    //         fetchTopics(); // Rafraîchit les posts
    //     } catch (err) {
    //         console.error("Erreur lors de la publication :", err);
    //     }
    // };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !category || !content || content === '<p><br></p>') {
            setError("Tous les champs sont obligatoires.");
            return;
        }

        try {
            // Ajouter le sujet dans Firestore
            const docRef = await addDoc(collection(db, "posts"), {
                title,
                category,
                content,
                timestamp: serverTimestamp(),
                author: username || "Anonyme",
                authorId: userId,
                replies: 0,
                likes: 0,
            });

            if (!docRef) {
                setError("Erreur lors de l'ajout du sujet. Réessayez plus tard.");
                return;
            }

            // Réinitialisation du formulaire
            setTitle("");
            setCategory("");
            setContent("");
            setError("");

            // Fermer le modal
            onClose();

            // Attendre un court délai pour assurer la mise à jour des données
            await new Promise(resolve => setTimeout(resolve, 500));

            // Recharger les sujets
            fetchTopics();

            // Message visuel pour l'utilisateur
            alert("Sujet ajouté avec succès !");

            // Redirection vers Forum.js
            navigate("/Forum");
        } catch (err) {
            console.error("Erreur lors de l'ajout du sujet :", err);
            setError("Une erreur est survenue. Réessayez plus tard.");
        }
    };


    const handleEditorChange = (content) => {
        setContent(content);
    };


    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="forum-modal-content">
                <h2>Créer un nouveau sujet</h2>
                <form onSubmit={handleSubmit}>
                    {error && <p className="error">{error}</p>}
                    <label style={{ display: "block" }}>
                        Titre :
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Titre du sujet"
                            required
                        />
                    </label>
                    <label
                        style={{
                            display: "block",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            marginLeft: "1rem",
                        }}
                        className="category-label"
                    >
                        Catégorie :
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        >
                            <option value="">Choisissez une catégorie</option>
                            {categories.map((cat) => (
                                <option key={cat} value={cat}>
                                    {cat}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label
                        style={{
                            display: "block",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            marginLeft: "1rem",
                        }}
                        className="content-label"
                    >
                        Contenu :
                        <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={(value) => setContent(value.trim() === "<p><br></p>" ? "" : value)} // Empêche les espaces vides
                            modules={modules}
                            placeholder="Rédigez votre message ici..."
                        />
                    </label>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="buttonForumModal"
                        style={{ backgroundColor: "#D90093", minWidth: "20vw", margin: "0 auto" }}
                    >
                        Publier
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="buttonForumModal"
                        style={{ backgroundColor: "#D90093", minWidth: "20vw", margin: "0 auto" }}
                    >
                        Annuler
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreatePostModal;

