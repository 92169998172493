import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavigationBar from "../components/Navigation";
import Footer from "../components/Footer";
import {
    collection,
    getDocs,
    getDoc,
    query,
    orderBy,
    deleteDoc,
    doc,
    addDoc,
    updateDoc,
    serverTimestamp,
    onSnapshot,
    where,
    limit,
    startAfter,
    setLastVisible,
    lastVisible,
    timestamp
} from "firebase/firestore";
import { db } from "../config/firebasedeconfig";
import "../styles/components/SubjectPage.scss";
import { UserContext } from "../UserContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { defaultAvatarUrl, defaultUsername, defaultEmail } from '../constants';
import { OnlineUsersContext } from "../OnlineUsersContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubjectPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { username, userId, messageCount, updateMessageCount, fetchUserMessageCount } = useContext(UserContext);
    const { onlineUsers } = useContext(OnlineUsersContext);
    const [subject, setSubject] = useState(null);
    const [replies, setReplies] = useState([]);
    const [newReply, setNewReply] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [error, setError] = useState("");


    // Fonction pour supprimer une réponse avec un toast de succès
    const handleDeleteReply = async (replyId) => {
        try {
            await deleteDoc(doc(db, 'posts', id, 'replies', replyId));
            toast.success("Réponse supprimée avec succès !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchReplies(); // Recharge les réponses après suppression
        } catch (err) {
            toast.error("La suppression de la réponse a échoué. Veuillez réessayer !");
            console.error("Erreur :", err);
        }
    };

    // Fonction pour modifier une réponse avec un toast de succès
    const handleEditReply = async (replyId, newContent) => {
        try {
            await updateDoc(doc(db, 'posts', id, 'replies', replyId), {
                content: newContent,
                updatedAt: new Date(),
            });
            toast.success("Réponse modifiée avec succès !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchReplies(); // Recharge les réponses après modification
        } catch (err) {
            toast.error("La modification de la réponse a échoué. Veuillez réessayer !");
            console.error("Erreur :", err);
        }
    };


    // Modules pour ReactQuill
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    // Charger les détails du sujet
    useEffect(() => {
        const fetchSubject = async () => {
            try {
                const subjectDoc = doc(db, "posts", id);
                const subjectSnapshot = await getDoc(subjectDoc);

                if (!subjectSnapshot.exists()) {
                    console.log("Le sujet n'existe pas.");
                    setSubject(null);
                    return;
                }

                const subjectData = {
                    id: subjectSnapshot.id,
                    ...subjectSnapshot.data(),
                };

                // Récupérer les informations de l'auteur
                if (subjectData.authorId) {
                    const userDocRef = doc(db, "users", subjectData.authorId);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        subjectData.avatar = userData.avatar || defaultAvatarUrl;
                        subjectData.author = userData.username || "Utilisateur inconnu";
                        subjectData.messageCount = await fetchUserMessageCount(subjectData.authorId); // Appel global
                    }
                } else {
                    subjectData.avatar = defaultAvatarUrl;
                    subjectData.author = "Auteur inconnu";
                }

                setSubject(subjectData);
            } catch (error) {
                console.error("Erreur lors de la récupération du sujet :", error);
            }
        };

        fetchSubject();
    }, [id]);

    // Charger les réponses avec pagination
    const fetchReplies = async () => {
        try {
            const postsQuery = query(
                collection(db, "posts", id, "replies"),
                orderBy("timestamp", "desc"),
                limit(10)
            );

            const querySnapshot = await getDocs(postsQuery);
            if (querySnapshot.empty) {
                console.log("Aucun post.");
                setReplies([]);
                return;
            }

            // On utilise Promise.all pour gérer les requêtes asynchrones
            const repliesData = await Promise.all(
                querySnapshot.docs.map(async (docSnap) => {
                    const reply = { id: docSnap.id, ...docSnap.data() };

                    if (reply.authorId) {
                        // Récupérer le doc user
                        const userRef = doc(db, "users", reply.authorId);
                        const userDoc = await getDoc(userRef);

                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            reply.avatar = userData.avatar || defaultAvatarUrl;
                            reply.authorName = userData.username || "Utilisateur inconnu";
                        } else {
                            reply.avatar = defaultAvatarUrl;
                            reply.authorName = "Auteur inconnu";
                        }

                        // **Récupérer le nombre de messages de l’auteur**
                        reply.messageCount = await fetchUserMessageCount(reply.authorId);
                    } else {
                        reply.avatar = defaultAvatarUrl;
                        reply.authorName = "Auteur inconnu";
                        reply.messageCount = 0;
                    }

                    return reply;
                })
            );

            setReplies(repliesData);
        } catch (error) {
            console.error("Erreur lors de la récupération des réponses :", error);
        }
    };


    useEffect(() => {
        fetchReplies();
    }, []);

    // Ajouter une nouvelle réponse
    const handleReplySubmit = async (e) => {
        e.preventDefault();
        if (!newReply || newReply === "<p><br></p>") {
            setError("Le contenu de la réponse est requis.");
            return;
        }
        try {
            const repliesRef = collection(db, "posts", id, "replies");
            await addDoc(repliesRef, {
                author: username || "Anonyme",
                authorId: userId,
                content: newReply,
                timestamp: serverTimestamp(),
            });
            setNewReply("");
            setError("");
            fetchReplies();
        } catch (err) {
            console.error("Erreur lors de l'ajout de la réponse :", err);
        }
    };

    const handleCancel = () => {
        navigate("/Forum"); // Redirige vers la page Forum.js
    };

    return (
        <>
            <ToastContainer />
            <div
                className="forum-page"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                    minHeight: "100vh",
                }}
            >
                <NavigationBar />
                <div className="subject-page"
                    style={{
                        marginTop: "1.25rem",
                        padding: "1.5rem",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "0.5rem",
                        boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)",
                        border: "2px solid #00FFFF",

                    }}
                >
                    {subject && (
                        <div className="subject-container" style={{ marginBottom: "1.5rem", padding: "1.5rem", backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "0.5rem", boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)", border: "2px solid #D90093" }}>
                            <h1>{subject.title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: subject.content }}></p>
                            <div className="subject-author" style={{ display: "flex", alignItems: "center", marginTop: "0.5rem", gap: "0.5rem", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                <div className="avatar-containerForumInfo" style={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: "1" }}>
                                    <span><p>Publié par : <span style={{ fontWeight: "bold", color: "#00FFFF", marginLeft: "0.25rem" }}>{subject.author}</span></p></span>
                                    <span><p>À : <span style={{ fontWeight: "bold", color: "#FF33CC", marginLeft: "0.25rem" }}>{new Date(subject.timestamp?.seconds * 1000).toLocaleString()}</span></p></span>
                                </div>
                                <div className="avatar-containerForumAvatarInfo" style={{ color: "#00FFFF", fontSize: "0.8rem", backgroundColor: "#200211", padding: "0.5rem", borderRadius: "0.25rem", boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)", flex: "1", border: "2px solid #D90093", minWidth: "50vw", width: "50vw" }}>
                                    <div className="avatar-containerForumHome"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "0.5rem",
                                            gap: "0.5rem",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <img
                                            src={subject.avatar || defaultAvatarUrl}
                                            alt="Avatar de l'auteur"
                                            className="avatar-subject"
                                        />
                                        <div className="avatar-containerForumTitreNbrMessages"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.125rem",
                                                fontSize: "0.8rem",
                                                flexDirection: "column",
                                                marginTop: "0.2rem",
                                                marginLeft: "0.5rem",
                                                color: "#00FFFF",
                                            }}
                                        >
                                            <span style={{ color: "#00FFFF", marginRight: "5.75rem" }}>
                                                Titre :{" "}
                                            </span>
                                            <span style={{ fontWeight: "bold", color: "white" }}>
                                                Nbr de messages : {subject.messageCount || 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="replies">
                        <h2>Réponses</h2>
                        {replies.map(reply => (
                            <div key={reply.id} className="reply" >
                                <div className="reply-author">
                                    <p dangerouslySetInnerHTML={{ __html: reply.content }}></p>
                                    {reply.updatedAt && (
                                        <small style={{ fontStyle: 'italic', color: 'red', marginBottom: "0.5rem", display: "block" }}>
                                            Modifié le {new Date(reply.updatedAt.seconds * 1000).toLocaleString()}
                                        </small>
                                    )}
                                    <div className="reply-author_description" style={{ display: "flex", alignItems: "center", marginTop: "0.5rem", backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "0.5rem", padding: "0.5rem", boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)" }}>
                                        <span style={{ display: "flex", flexDirection: "column", marginLeft: "0.5rem", flex: "1" }}>
                                            <p>Publié par : <span style={{ fontWeight: "bold", color: "#00FFFF", marginLeft: "0.25rem" }}>{reply.author}</span></p>
                                            <p>À : <span style={{ fontWeight: "bold", color: "#FF33CC", marginLeft: "0.25rem" }}>{new Date(reply.timestamp?.seconds * 1000).toLocaleString()}</span></p>
                                        </span>
                                        {reply.authorId === userId && (
                                            <div className="reply-actionsSubjectPage" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "2.5rem", flex: "0.575" }}>
                                                <button
                                                    onClick={async () => {
                                                        if (window.confirm("Voulez-vous vraiment supprimer cette réponse ?")) {
                                                            try {
                                                                const replyRef = doc(db, "posts", id, "replies", reply.id);
                                                                await deleteDoc(replyRef);
                                                                fetchReplies();
                                                            } catch (err) {
                                                                console.error("Erreur lors de la suppression de la réponse :", err);
                                                            }
                                                        }
                                                    }}
                                                    style={{ marginRight: "0.5rem" }}
                                                >
                                                    Supprimer
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        navigate(`/edit-reply/${id}/${reply.id}`);
                                                    }}
                                                    style={{ marginRight: "0.5rem" }}
                                                >
                                                    Modifier
                                                </button>
                                            </div>
                                        )}
                                        <div className="base_avatar-containerForumHome" style={{ color: "#00FFFF", fontSize: "0.8rem", backgroundColor: "#200211", padding: "0.5rem", borderRadius: "0.25rem", boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)", flex: "1", minWidth: "15rem", border: "1px solid #00FFFF" }}>
                                            <div className="avatar-containerForumHomeImg"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "0.5rem",
                                                    gap: "0.5rem",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <img
                                                    src={reply.avatar || defaultAvatarUrl}
                                                    alt="Avatar de l'auteur"
                                                    className="avatar-subject"
                                                />
                                                <div className="avatar-containerForumHomeReplyTitreContainer"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "0.125rem",
                                                        fontSize: "0.8rem",
                                                        flexDirection: "column",
                                                        marginTop: "0.2rem",
                                                        marginLeft: "0.5rem",
                                                        color: "#00FFFF",
                                                    }}
                                                >
                                                    <span style={{ color: "#00FFFF", marginRight: "5.75rem" }}>
                                                        Titre :{" "}
                                                    </span>
                                                    {replies.map((reply) => (
                                                        <div key={reply.id}>
                                                            {/* … */}
                                                            <span style={{ fontWeight: "bold", color: "white" }}>
                                                                Nbr de messages : {reply.messageCount || 0}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {lastVisible && (
                            <button onClick={fetchReplies}>Charger plus de réponses</button>
                        )}
                    </div>

                    <form onSubmit={handleReplySubmit}>
                        {error && <p className="error">{error}</p>}
                        <ReactQuill
                            theme="snow"
                            value={newReply}
                            onChange={setNewReply}
                            modules={modules}
                            placeholder="Rédigez votre message ici..."
                            className="quill-editor"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", marginBottom: "1rem" }} className="button-container_PublierRetournerSubjectPage">
                            <button type="submit" className="buttonEditerSubject" style={{ width: "38%", marginLeft: "1rem" }}>
                                Publier la réponse
                            </button>
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="buttonAnnulerSubject"
                                style={{ width: "38%", marginLeft: "1rem" }}
                            >
                                Retourner au forum
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="footer" >
                <Footer />
            </div>
        </>
    );
};

export default SubjectPage;





