import React, { createContext, useState, useEffect, useCallback } from 'react';
import { auth } from './config/firebasedeconfig';
import { collection, doc, getDoc, setDoc, deleteDoc, userRef, updateDoc, getDocs, query, where, collectionGroup  } from "firebase/firestore";
import { db } from './config/firebasedeconfig';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [banner, setBanner] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null); // Pour stocker l'intention de redirection
  const [messageCount, setMessageCount] = useState(0);


  const fetchUserData = async (user) => {
    if (!user || !user.uid) {
      console.warn("Utilisateur non connecté ou UID invalide.");
      return;
    }
    const userRef = doc(db, "users", user.uid);    
    // if (!user || !user.uid) return;
    // const userRef = doc(db, "users", user.userId); // user.userId
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const { username: userUsername, email: userEmail, banner: userBanner, avatar: userAvatar } = userDoc.data();
      setUsername(userUsername);
      setEmail(userEmail);
      setBanner(userBanner);
      setAvatar(userAvatar);
      setUserIsLoggedIn(true);
      setIsLoggedIn(true);
    }
  };
  

  const updateContext = (newUsername, newEmail, newAvatar, newBanner, newIsLoggedIn, newUserIsLoggedIn) => {
    if (newUsername !== username) {
      setUsername(newUsername);
    }
    if (newEmail !== email) {
      setEmail(newEmail);
    }
    if (newAvatar !== avatar) {
      setAvatar(newAvatar);
    }
    if (newBanner !== banner) {
      setBanner(newBanner);
    }
    if (newIsLoggedIn !== isLoggedIn) {
      setIsLoggedIn(newIsLoggedIn);
    }
    if (newUserIsLoggedIn !== userIsLoggedIn) {
      setUserIsLoggedIn(newUserIsLoggedIn);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
            // Met à jour isOnline à true dans Firestore
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, { isOnline: true });

            // Récupère les informations utilisateur
            setUserId(user.uid);
            fetchUserData(user);
        } else {
            // Met à jour isOnline à false dans Firestore
            if (userId) {
                const userRef = doc(db, "users", userId);
                await updateDoc(userRef, { isOnline: false });
            }

            // Réinitialise le contexte utilisateur
            resetContext();
        }
    });

    return () => unsubscribe();
}, [userId]);
  
  const resetContext = () => {
    setIsLoggedIn(false);
    setUserIsLoggedIn(false);
    setUsername(null);
    setEmail(null);
    setAvatar(null);
    setBanner(null);
  };

  useEffect(() => {
    console.log("Debugging UserContext:", username, email, avatar);
  }, [username, email, avatar]);

  useEffect(() => {
    const handleBeforeUnload = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { isOnline: false });
      }
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Compter les messages de l'utilisateur
  const fetchUserMessageCount = useCallback(async (authorId) => {
    try {
      console.log("fetchUserMessageCount appelé pour :", authorId);
  
      const postsQuery = query(collection(db, "posts"), where("authorId", "==", authorId));
      const postsSnapshot = await getDocs(postsQuery);
      const postCount = postsSnapshot.size;
  
      const repliesQuery = query(collectionGroup(db, "replies"), where("authorId", "==", authorId));
      const repliesSnapshot = await getDocs(repliesQuery);
      const repliesCount = repliesSnapshot.size;
  
      console.log(`Posts : ${postCount}, Réponses : ${repliesCount}, Total : ${postCount + repliesCount}`);
      return postCount + repliesCount;
    } catch (err) {
      console.error("Erreur lors du comptage des messages :", err);
      return 0; // Valeur par défaut en cas d'erreur
    }
  }, []);

  // Mise à jour du nombre de messages de l'utilisateur
  const updateMessageCount = useCallback(async (userId) => {
    if (userId) {
      const count = await fetchUserMessageCount(userId);
      setMessageCount(count);
    }
  }, [fetchUserMessageCount, setMessageCount]);


 // Appeler la fonction de comptage des messages
  useEffect(() => {
    if (userId) {
      fetchUserMessageCount(userId);
      updateMessageCount(userId);
    }
  }, [userId, messageCount, updateMessageCount]);
  

  // return userId && (
  return (
    <UserContext.Provider value={{
      username, setUsername, email, setEmail,
      avatar, setAvatar, banner, setBanner,
      isLoggedIn, setIsLoggedIn, updateContext, resetContext, fetchUserData,
      userIsLoggedIn, setUserIsLoggedIn,       
      userId, setUserId, redirectTo, setRedirectTo, messageCount, updateMessageCount, setMessageCount, fetchUserMessageCount
    }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;






